import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import AppLayout from './AppLayout'
import HomePage from './pages/HomePage'
import log1 from './pages/log1'
import log2 from './pages/log2'
import log3 from './pages/log3'
import log4 from './pages/log4'
import log5 from './pages/log5'




export default () => {
  return (
    <Router>
      <AppLayout>
        <Route path="/" exact component={HomePage} />
        <Route path="/log1" exact component={log1} />
        <Route path="/log2" exact component={log2} />
        <Route path="/log3" exact component={log3} />
        <Route path="/log4" exact component={log4} />
        <Route path="/log5" exact component={log5} />
      </AppLayout>
    </Router>
  )
}
