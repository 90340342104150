import React from 'react'
import { Collapse } from 'reactstrap'
import { Link } from 'react-router-dom'
import Logo from './components/Logo'

const menuStructure = [
  {
    title: 'MPG',
    links: [
      {
        title: 'SEASON 1',
        links: [{ title: 'test', path: '/log3' }, { title: 'run-01', path: '/log5' }],
      },
    ],
  },
  {
    title: 'JPG',
    links: [
      {
        title: 'MISC',
        links: [{ title: 'doodles', path: '/log1' }, { title: 'doodles', path: '/log2' }],
      },
      {
        title: 'SPRAYS',
        links: [{ title: '3d-printed', path: '/log6' }],
      },
      {
        title: 'STICKS',
        links: [{ title: 'skully', path: '/log4' },],
      },
    ],
  },

]

export default class AppLayout extends React.Component {
  state = { menuOpen: -1, secondaryMenuOpen: -1 }
  render() {
    return (
      <div id="wrapper">
        <div id="appLayout">
          <Logo />
          <div id="nav-menu">
            {menuStructure.map(({ title, links }, i) => (
              <div className="primary-nav" key={i}>
                <span onClick={() => this.setState({ menuOpen: this.state.menuOpen === i ? -1 : i })}>{title}</span>
                <Collapse isOpen={this.state.menuOpen === i}>
                  {links.map(({ title: secondaryTitle, links: secondaryLinks }, ind) => (
                    <div className="secondary-nav" key={ind}>
                      <span
                        onClick={() =>
                          this.setState({ secondaryMenuOpen: this.state.secondaryMenuOpen === ind ? -1 : ind })
                        }
                      >
                        {secondaryTitle}
                      </span>
                      <Collapse isOpen={this.state.secondaryMenuOpen === ind}>
                        {secondaryLinks.map(({ title: tertiaryTitle, path }, index) => (
                          <div className="tertiary-nav" key={index}>
                            <Link to={path}>{tertiaryTitle}</Link>
                          </div>
                        ))}
                      </Collapse>
                    </div>
                  ))}
                </Collapse>
              </div>
            ))}
          </div>
        </div>
        <div id="content">{this.props.children}</div>
      </div>
    )
  }
}
