import React from 'react'


export default () => (
  <div>
    <br></br>
    <a id="text">*FTP</a>
    <br></br>
    <a id="text3">HEAVY.EXE</a>
    <br></br>
    <a id="text3">v 4.3</a>
    <br></br>
    <a id="text">block chain network architecture</a>
    <br></br>
    <a href="https://www.patreon.com/bePatron?u=20714240" data-patreon-widget-type="become-patron-button">Become a Patron!</a><script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
  </div>
)



